import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Blog() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadArticles = async () => {
      try {
        const response = await fetch('http://localhost:1337/api/articles');
        const data = await response.json();
        setArticles(data);
      } catch (error) {
        console.error('Erreur lors du chargement des articles:', error);
      } finally {
        setLoading(false);
      }
    };

    loadArticles();
  }, []);

  if (loading) {
    return <div>Chargement...</div>;
  }

  return (
    <div>
      <h1>Blog</h1>
      <ul>
        {articles.map(article => (
          <li key={article.id}>
            <Link to={`/blog/${article.slug}`}>{article.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Blog; 